import Web3 from "web3";
import { abi as ABI } from "./ABI";
import ADDRESS from "./ADDRESS";
import web3 from "web3";

let selectedAccount: string;
let nftContract: any;

let name: string;
let cost: number;
let maxSupply: number;
let totalSupply: number;
let wallet: any;

declare global {
  interface Window {
    ethereum?: any;
    web3?: any;
  }
}

export const init = () => {
  const provider = window.ethereum;

  if (typeof provider !== "undefined") {
    //  MetaMask is installed!

    provider
      .request({ method: "eth_requestAccounts" })
      .then((accounts: any) => {
        selectedAccount = accounts[0];
        console.log(`Selected account is ${selectedAccount}`);
      })
      .catch((err: any) => {
        console.error(err);
      });

    window.ethereum.on("accountsChanged", (accounts: any) => {
      selectedAccount = accounts[0];
      console.log(`Selected account changed to ${selectedAccount}`);
    });

    const web3 = new Web3(provider);

    nftContract = new web3.eth.Contract(ABI, ADDRESS);
  }
};

export const getNFTInfo = async () => {
  name = await nftContract?.methods.name().call();
  totalSupply = await nftContract?.methods.totalSupply().call();
  cost = Number(await nftContract?.methods.cost().call()) / 1000000000000000000;
  maxSupply = await nftContract?.methods.maxSupply().call();
  wallet = await nftContract?.methods.walletOfOwner(selectedAccount).call();

  return {
    name,
    cost,
    maxSupply,
    totalSupply,
    wallet,
  };
};

export const mint = () => {
  const mintAmount = 1;
  console.log("calling mint");
  return new Promise((resolve: any) => {
    nftContract?.methods
      .mint(mintAmount) // Pass the `mintAmount` here
      .send({
        from: selectedAccount,
        value: web3.utils.toWei((0.0299).toString(), "ether"),
        chainId: 84532,
        gas: 5000000,
      })
      .then((data: any) => {
        console.log(data);
        resolve(data);
      })
      .catch((err: any) => {
        console.error(err);
        resolve();
      });
  });
};

export const getOwnerNFTs = () => {
  return nftContract?.methods.walletOfOwner();
};
