import { createContext, useState } from 'react';
import useWeb3 from '../web3/useWeb3';

const Web3Context = createContext({});

export const Web3ContextProvider = ({ children }: any) => {
  const initialData = useWeb3();

  const [contextData, setContextData] = useState<any>(initialData);

  return (
    <Web3Context.Provider value={{ contextData, setContextData }}>
      {children}
    </Web3Context.Provider>
  )
}


export default Web3Context;