import { useEffect, useState } from "react";
import { init, getNFTInfo, mint } from "./web3Client";

const useWeb3 = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [NFTInfo, setNFTInfo] = useState<any>({
    name: null,
    cost: null,
    maxSupply: null,
    totalSupply: null,
  })

  useEffect(() => {
    const initialize = async () => {
      await init();

      const info = await getNFTInfo();

      setNFTInfo(info);
    }

    // initialize();
  }, []);

  const handleMint = async () => {
    setIsLoading(true);

    await mint();

    setIsLoading(false);

  };

  return { handleMint, isLoading, NFTInfo };
};

export default useWeb3;
